

import {Link, Trans, useTranslation, useI18next}
from 'gatsby-plugin-react-i18next';


//import kakieramail from "../img/R_kariera_10/kariera_tlo.jpg";
//import kakieramail from "../img/R_kariera_10/kakieramail.png";
//

import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";


export default function Header(props) {



    return (
            <React.Fragment>

                <section id="career" class="career firstSection">

                    <h1>{i18next.t('CareerH1')}</h1>
                    <p>{i18next.t('CareerP')}</p>

                    <div class="link" onClick={() => window.location = 'mai' + '' + 'lto:kari' + 'era@inst' + 'anta.pl'}>
                    </div>

                </section>



            </React.Fragment>

            )


}
